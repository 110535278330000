import React from 'react';
import { Navigate } from 'react-router-dom';
import { UserAuth } from '../context/AuthContext';
const Protected = ({ children }) => {
  // Retrieving a boolean value from local storage
const loggedIn = JSON.parse(localStorage.getItem("loggedIn")); // Use JSON.parse to convert it back to a boolean
  if (!loggedIn ) {
    return <Navigate to='/login' />;
  }
  return children;
};

export default Protected;