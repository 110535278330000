import React, { useContext } from "react";
import {
  BoldLink,
  BoxContainer,
  FormContainer,
  Input,
  LineText,
  MutedLink,
  SubmitButton,
} from "./common";
import { Marginer } from "../marginer";
import { AccountContext } from './accountContext';
import { UserAuth, register } from "../../context/AuthContext";

import {  useNavigate } from "react-router-dom";

export function SignupForm(props) {

  const { switchToSignin } = useContext(AccountContext);
  const navigate = useNavigate();

  return (
    <BoxContainer>
      <FormContainer onSubmit={async (v)=>{
        v.preventDefault()

        if(v.target.password.value === v.target.confirm.value){
          if(v.target.password.value.length < 6){
            alert("Password must be at least 6 characters long")
            return;
          }
       try{
       const user= await register(v.target.email.value,v.target.password.value)
        console.log(user)
        if(user){
          navigate("/")
          }
        else{
          alert("Email already exist, Please try to login or use another email to signup")}
        }catch(e){
          alert(e.message)
          alert("Email already exist, Please try to login or use another email to signup")
        
        }}else{
          alert("Password and Confirm Password are not same")
          v.preventDefault()
        }
      }}>
        <Input type="text" name="name" placeholder="Full name" onChange={(e)=>console.log(e.target.value)} />
        <Input type="email" name="email" placeholder="Email" />
        <Input type="password" name="password" placeholder="Password" />
        <Input type="password"  placeholder="Confirm password" name="confirm" />
        {/* Submit Button on press print all values */}
        <Marginer direction="vertical" margin={10} />
        <center><SubmitButton type="submit" onClick={(e)=>{
        }}>Signup</SubmitButton></center>
      </FormContainer>

      <Marginer direction="vertical" margin="5px" />
      <LineText>
        Already have an account?{" "}
        <BoldLink onClick={switchToSignin} href="#">
          Signin
        </BoldLink>
      </LineText>
    </BoxContainer>
  );
}