import { useState } from 'react'
import '@chatscope/chat-ui-kit-styles/dist/default/styles.min.css';
import VenvGpt from './pages/venvGpt.jsx';
import { Route, Routes } from 'react-router-dom';
import LoginPage from './pages/login.jsx';
import styled from "styled-components";

function App() {
  
  return (
    
    <div>
        <Routes>
          <Route path="/" element={<VenvGpt />} />
         <Route path="/login" element={<LoginPage />} /> 
        </Routes>
    </div>
  )
}

export default App    