
const BaseURL = 'http://138.197.108.70:8007';
export const login=async (usernnmes, password)=>{
   const res = await  fetch(BaseURL+'/users/login', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({

      'email':usernnmes,
      'password':password,
    }),
  })
  // .then((response) => response.json())
  //   .then((data) => {
  //     localStorage.setItem('token',data.token);
  //     localStorage.setItem('username',usernnmes);
  //     localStorage.setItem('password',password);
  //     localStorage.setItem('loggedIn',true);
  //     console.log(data['login']);
  //     if (data['login']) {
  //       useNavigate('/')
  //     }
  //     else{
  //       alert('Please make sure you entered correct email and password');
  //     }
  
  //     return data['login'];
  //   })
  //   .catch((error) => {
  //     console.error('Error:', error);
  //     return false;
  //   });
  const data = await res.json();
  localStorage.setItem('token',data.token);
  localStorage.setItem('username',usernnmes);

  console.log(data['login']);
  if (data['login']) {

  localStorage.setItem('loggedIn',true);
    return true;
  }
  else{
    return false; 
  }
}


export const register=async (email, password)=>{
  const res = await  fetch(BaseURL+'/users/register', {
   method: 'POST',
   headers: {
     'Content-Type': 'application/json',
   },
   body: JSON.stringify({

     'email':email,
     'password':password,
   }),
 })
 const data = await res.json();

 


 console.log(data['login']);
 if (data['login']) {
  localStorage.setItem('loggedIn',true);
  localStorage.setItem('token',data.token);
  localStorage.setItem('username',email);
   return true;
 }
 else{
   return false; 
 }
}